import productViewGallery from "../components/productViewGallery";
import video from "../components/video";
import Vue from 'vue';

Vue.component('modal-view-gallery', {
    data() {
        var images = []

        document.querySelectorAll("[data-image-url]").forEach(function(image) {
            images.push({ url: image.dataset.imageUrl })
        })

        let videos = gon.product_videos.map((video) => {
            let imageUrl = video.image.url ? video.image.url : 'http://i.ytimg.com/vi/lvxC0MzdRfk/maxresdefault.jpg'
            let image = `background-image: url(${imageUrl})`

            return {
                link: video.link,
                image: image
            }
        })

        console.log(videos)

        return {
            images: images,
            videos: videos
        };
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    methods: {
        render(idPhoto) {
            let html = document.querySelector('html');
            html.classList.add('is-open-modal');

            productViewGallery(idPhoto);
            video();
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.$emit('close');
            }
        },
    },
    template:
        `<div class="popup-wrapper popup-gallery">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <button class="popup-close" @click="$emit('close')"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></button>        
                    <div class="popup popup_gallery">
                        <div class="product-view-gallery js-product-view-gallery">
                            <!-- thumbs -->
                            <div data-slider="thumbs" class="product-view-gallery__thumbs">
                                <div class="product-view-gallery__thumbs-container swiper-container">
                                    <div class="product-view-gallery__thumbs-wrapper swiper-wrapper">
                                        <div v-for="(video, index) in videos"
                                             class="product-view-gallery__thumbs-slide swiper-slide">
                                            <svg class="icon icon-play" width="33" height="33">
                                                <use xlink:href="#icon-play"></use>
                                            </svg>
                                        </div>
        
                                        <div v-for="(image, index) in images"  class="product-view-gallery__thumbs-slide swiper-slide">
                                            <img :src="image.url" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <!-- main -->
                            <div data-slider="main" class="product-view-gallery__main">
                                <div class="product-view-gallery__main-container swiper-container">
                                    <div class="product-view-gallery__main-wrapper swiper-wrapper">  
                                        <div v-for="(video, index) in videos" 
                                             :data-id-slide="'v_' + index" 
                                             class="product-view-gallery__main-slide swiper-slide">
                                            <div class="product-view-gallery__main-slide-content">
                                                <div class="video-box js-video">
                                                    <div data-video="video-preview" class="video-box__preview" :style="video.image">
                                                        <button type="button" class="video-box__play-button play-button"></button>
                                                    </div>
                                                    <iframe :data-src="video.link" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(image, index) in images" :data-id-slide="'p_' + index" class="product-view-gallery__main-slide swiper-slide">
                                            <div class="product-view-gallery__main-slide-picture">
                                                <img :src="image.url" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="swiper-arrow-circle swiper-arrow-circle_prev swiper-arrow-circle_grey swiper-button-prev">
                                    <svg width="16" height="12" class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                </div>
                                <div class="swiper-arrow-circle swiper-arrow-circle_next swiper-arrow-circle_grey swiper-button-next">
                                    <svg width="16" height="12" class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`,
});